<section class="section service-area bg-gray overflow-hidden ptb_100">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-12 col-lg-6 order-2 order-lg-1">
                <!-- Service Text -->
                <div class="service-text pt-4 pt-lg-0">
                    <h2 class="text-capitalize mb-4">Try TempTrack today and experience the power of data-driven temporary staffing management.</h2>
                    <!-- Service List -->
                    <ul class="service-list">
                        <!-- Single Service -->
                        <li class="single-service media py-2">
                            <div class="service-icon pr-4">
                                <span><i class="fab fa-buffer"></i></span>
                            </div>
                            <div class="service-text media-body">
                                <p>Reduce the cost of hiring and managing temporary staff.</p>
                            </div>
                        </li>
                        <!-- Single Service -->
                        <li class="single-service media py-2">
                            <div class="service-icon pr-4">
                                <span><i class="fas fa-brush"></i></span>
                            </div>
                            <div class="service-text media-body">
                                <p>Easily track the performance and productivity of your temporary staff in real-time..</p>
                            </div>
                        </li>
                        <!-- Single Service -->
                        <li class="single-service media py-2">
                            <div class="service-icon pr-4">
                                <span><i class="fas fa-burn"></i></span>
                            </div>
                            <div class="service-text media-body">
                                <p>Communicate with your temporary staff, even when you're on-the-go.</p>
                            </div>
                        </li>
                        <!-- Single Service -->
                        <li class="single-service media py-2">
                            <div class="service-icon pr-4">
                                <span><i class="fas fa-cart-arrow-down"></i></span>
                            </div>
                            <div class="service-text media-body">
                                <p>Streamlines your temporary staffing management process, giving you complete control over your workforce.</p>
                            </div>
                        </li>
                    </ul>
                    <!-- <a href="#" class="btn btn-bordered mt-4">Learn More</a> -->
                </div>
            </div>
            <div class="col-12 col-lg-4 order-1 order-lg-2">
                <!-- Service Thumb -->
                <div class="service-thumb mx-auto">
                    <!-- Thumb Top -->
                    <div class="thumb-top" data-aos="zoom-in" data-aos-offset="300" data-aos-duration="700">
                        <img src="assets/img/service_thumb_top.png" alt="">
                    </div>
                    <!-- Thumb Certer -->
                    <div class="thumb-center">
                        <img src="assets/img/service_thumb.png" alt="">
                    </div>
                    <!-- Thumb Bottom -->
                    <div class="thumb-bottom" data-aos="fade-left" data-aos-duration="500">
                        <img src="assets/img/service_thumb_bottom.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>