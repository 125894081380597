<section class="section faq-area ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Frequently Asked Questions</h2>
                    <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                    <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!-- FAQ Content -->
                <div class="faq-content">
                    <!-- Appia Accordion -->
                    <div class="accordion" id="appia-accordion">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <button class="btn" type="button" data-toggle="collapse" data-target="#collapseOne">
                                                How to install Appia?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseOne" class="collapse show" data-parent="#appia-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa.
                                        </div>
                                    </div>
                                </div>
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo">
                                                Can I get support from the Author?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTwo" class="collapse" data-parent="#appia-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa.
                                        </div>
                                    </div>
                                </div>
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseThree">
                                                Contact form isn't working?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseThree" class="collapse" data-parent="#appia-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseFour">
                                                What about the events?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFour" class="collapse" data-parent="#appia-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa.
                                        </div>
                                    </div>
                                </div>
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <button class="btn" type="button" data-toggle="collapse" data-target="#collapseFive">
                                                How can I get product update?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFive" class="collapse show" data-parent="#appia-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa.
                                        </div>
                                    </div>
                                </div>
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseSix">
                                                Is this template support rtl?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseSix" class="collapse" data-parent="#appia-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>