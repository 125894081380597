<section class="start-free-area style-two">
    <div class="container">
        <div class="col-12">
            <!-- Start Free Content -->
            <div class="start-free-content text-center shadow-lg" data-aos="fade-up" data-aos-duration="800">
                <!-- Start Free Content -->
                <div class="start-free-text">
                    <strong>Try Temp Track App for free!</strong>
                    <h2 class="mt-2 mb-3">Temp Track is available for all devices</h2>
                    <p class="start-free-details d-none d-lg-block">Thousand's of customer's loves Temp Track! Experience the benefits of our mobile app for hiring, tracking, and managing temporary staff today. Try it out and see how it can transform the way you manage your temporary workforce.</p>
                    <!-- Store Buttons -->
                    <div class="button-group store-buttons d-flex justify-content-center">
                        <a href="#">
                            <img src="assets/img/google_play.svg" alt="">
                        </a>
                        <a href="#">
                            <img src="assets/img/app_store.svg" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>