<section id="benifits" class="section benifits-area style-two ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Start Your Business with Temp Track</h2>
                    <p class="d-none d-sm-block mt-4">Your ultimate staffing solution for efficient tracking and management. Our state-of-the-art mobile app provides a seamless experience in workforce management, guaranteeing that your staffing needs are met with precision. TempTrack empowers you to:

                    </p>
                    <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                </div>
            </div>
        </div>
        <div class="row benifits-content">
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-4 p-0" data-aos="fade-up">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/promo_5.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Mobile Workforce Management Simplified</h3>
                        <p>Easily manage and track your temporary workforce from your mobile device.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-4 p-0" data-aos="fade-up" data-aos-delay="200">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/promo_6.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Efficient Temp Worker Management</h3>
                        <p>Streamline your staffing processes by quickly requesting and approving temp workers.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-4 p-0" data-aos="fade-up" data-aos-delay="400">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/promo_7.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Data-Driven Staffing Insights"</h3>
                        <p>Access real-time data and analytics on your staffing performance to make informed decisions.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-4 p-0" data-aos="fade-up" data-aos-delay="600">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/promo_8.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Timely Event & Deadline Notifications</h3>
                        <p>Receive notifications and alerts to stay on top of important events and deadlines.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-4 p-0" data-aos="fade-up" data-aos-delay="800">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/promo_9.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Seamless Communication Hub</h3>
                        <p>Communicate with your staffing agency and temporary workers directly through the app.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-4 p-0" data-aos="fade-up" data-aos-delay="1000">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/promo_10.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Performance-driven Workforce Optimization</h3>
                        <p>Provide real-time insights into employee performance and ensuring efficient workforce management.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>