<section id="home" class="section welcome-area overlay-dark d-flex align-items-center">
    <div class="container">
        <div class="row align-items-center">
            <!-- Welcome Intro Start -->
            <div class="col-12 col-md-7 col-lg-7">
                <div class="welcome-intro">
                    <h1 class="text-white">The Ultimate Staffing Solution For Seamless Staff Management</h1>
                    <p class="text-white my-4">Streamline Workforce Management with Effortless Tracking and Efficient Staff Management</p>
                    <!-- Store Buttons -->
                    <div class="button-group store-buttons d-flex">
                        <a href="#">
                            <img src="assets/img/google_play.svg" alt="">
                        </a>
                        <a href="#">
                            <img src="assets/img/app_store.svg" alt="">
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5 col-lg-5">
                <!-- Welcome Thumb -->
                <div class="welcome-thumb text-center mx-auto">
                    <img src="assets/img/welcome_mockup_2.png" alt="">
                </div>
            </div>
        </div>
    </div>
</section>